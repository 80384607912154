import * as React from "react";
import type { HeadFC } from "gatsby";

import { Web3Providers } from "../contexts/AppContext";

import polakLeaderTableMockupNFT from "../images/ranking/leader-1.svg";
import { NavBar } from "../components/NavBar/NavBar";
import Masonry from "react-masonry-css";
import { PageHeader } from "../components/PageHeader/PageHeader";
import { MemeItem } from "../components/MemeItem/MemeItem";
import { GoHomeButton } from "../components/Items/Buttons/Buttons";
import "../styles/meme-page-styles.scss";
import { MemesService } from "../services/Memes";
import { Memes } from "../types";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { SEO } from "../components/SEO";

const masonryBreakpoints = {
  default: 4,
  1000: 1,
};

// const partnerItems = [
//   <MemeItem
//     name={"ThePolak"}
//     username={"ThePolakNFT"}
//     mediaUrl={polakLeaderTableMockupNFT}
//     description={`DD Polska!

//   Nie wiem jak tam Wasze barometry ale nasze wskazuja, że dziś środa.
//   Powodzenia we wszystkim co robicie.
//   Z fartem! 🫡🇵🇱`}
//     isImage={true}
//     isMobileView={false}
//   />,
//   <MemeItem
//     name={"ThePolak"}
//     username={"ThePolakNFT"}
//     mediaUrl={polakLeaderTableMockupNFT}
//     description={`DD Polska!

//   Nie wiem jak tam Wasze barometry ale nasze wskazuja, że dziś środa.
//   Powodzenia we wszystkim co robicie.
//   Z fartem! 🫡🇵🇱`}
//     isImage={true}
//     isMobileView={false}
//   />,
//   <MemeItem
//     name={"ThePolak"}
//     username={"ThePolakNFT"}
//     mediaUrl={polakLeaderTableMockupNFT}
//     description={`DD Polska!

//   Nie wiem jak tam Wasze barometry ale nasze wskazuja, że dziś środa.
//   Powodzenia we wszystkim co robicie.
//   Z fartem! 🫡🇵🇱`}
//     isImage={true}
//     isMobileView={false}
//   />,
// ];

const IndexPage = ({ serverData }: { serverData: Memes[] }) => {
  return (
    <Web3Providers>
      <IndexPageComponent memes={serverData} />
    </Web3Providers>
  );
};
const IndexPageComponent = ({ memes }: { memes: Memes[] }) => {
  return (
    <Web3Providers>
      <main className="memy-page">
        <NavBar />
        <div className="page-content">
          <PageHeader
            title="Memy"
            subtitle="Przedstawiamy Wam zbiór memów stworzonych przez naszą społeczność, które w przeciwieństwie do kabaretów sprawią, że się przynajmniej raz zaśmiejecie.Chcesz, żeby Twój mem trafił na ścianę sławy? 
          Oznacz swój post na twitterze hasztagiem #ThePolacymeme"
          />

          <section className="memes-view__section memy">
            <div className="memes-view__box">
              {/* <Masonry
                breakpointCols={masonryBreakpoints}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              > */}
              {memes &&
                memes.map(meme => {
                  return (
                    <TwitterTweetEmbed
                      tweetId={meme.TweetId}
                      options={{ width: 300 }}
                    />
                  );
                })}
              {/* </Masonry> */}
            </div>
            <GoHomeButton />
          </section>
        </div>
      </main>
    </Web3Providers>
  );
};

export default IndexPage;

// export const Head: HeadFC = () => <title>Memy</title>;
// export { Head } from "../components/SEO";
export const Head: HeadFC = () => <SEO subtitle="Memy" />;

export async function getServerData() {
  try {
    const memesResponse = await MemesService.getMemes();
    if (memesResponse instanceof Error) throw memesResponse;
    const memes = memesResponse.filter(meme => meme.TweetId !== undefined);

    return {
      props: memes,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
}
